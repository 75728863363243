var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quick_wrap"},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("board.settingPage.title"))+" ")]),_c('div',{staticClass:"button_wrap"},[_c('el-button',{attrs:{"icon":"iconfont icon-exit"},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t("btn.exitEditBtn")))])],1),_c('div',{staticClass:"main_wrap"},[_c('div',[_c('span',{staticClass:"label-weight"},[_vm._v(_vm._s(_vm.$t("board.settingPage.name"))+":")]),_c('div',[_c('el-input',{attrs:{"placeholder":_vm.$t('placeholder.input'),"size":"small"},on:{"blur":_vm.settings},model:{value:(_vm.form_item.name),callback:function ($$v) {_vm.$set(_vm.form_item, "name", $$v)},expression:"form_item.name"}})],1)]),_c('div',[_c('span',{staticClass:"label-weight"},[_vm._v(_vm._s(_vm.$t("board.settingPage.bar"))+":")]),_c('div',{staticClass:"block_warp"},[_vm._l((_vm.blockArr.boardColumnList),function(item,index){return _c('div',{key:index,style:({
            color: item.color,
            backgroundColor: item.backgroundColor,
          }),attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.dragging(index)},"dragover":function($event){return _vm.dragover(index)},"dragend":_vm.dragend}},[_c('div',[_c('i',{staticClass:"iconfont icon-six_pot",staticStyle:{"color":"rgb(127, 127, 128)"}}),_c('i',{staticClass:"iconfont icon-delete",staticStyle:{"float":"right","color":"rgb(127, 127, 128)"},on:{"click":function($event){return _vm.delete_node(item)}}})]),_c('div',{staticClass:"status_List"},[(_vm.project_type !== 'SCRUM')?_c('p',{staticStyle:{"color":"rgba(112, 112, 112, 1)","font-size":"12px","height":"auto","padding":"0","margin":"6px 0"}},[_vm._v(" "+_vm._s(_vm.$t("board.settingPage.maxCardNum"))+": ")]):_vm._e(),(_vm.project_type !== 'SCRUM')?_c('p',{staticStyle:{"padding":"0","margin":"0"}},[_c('el-input-number',{attrs:{"min":1,"max":5000,"size":"small"},on:{"change":function($event){return _vm.number_change($event, item)}},model:{value:(item.number),callback:function ($$v) {_vm.$set(item, "number", $$v)},expression:"item.number"}})],1):_vm._e(),_vm._l((item.statusDtoList),function(list,list_index){return _c('el-tooltip',{key:list_index,attrs:{"disabled":true,"effect":"light","content":list.name,"placement":"top"}},[_c('div',{class:{
                  TODO_class: list.statusCategory === 'TODO',
                  IN_PROGRESS_class: list.statusCategory === 'IN_PROGRESS',
                  DONE_class: list.statusCategory === 'DONE',
                },attrs:{"draggable":""},on:{"dragstart":function($event){$event.stopPropagation();return _vm.dragging_status(index, list_index)},"dragend":function($event){$event.stopPropagation();return _vm.dragend_status.apply(null, arguments)}}},[_vm._v(" "+_vm._s(list.name)+" ")])])})],2),_c('div',[_vm._v(" "+_vm._s(item.name)+" ")])])}),_c('div',{staticClass:"add_block",on:{"click":_vm.add_block,"dragover":_vm.unassigned_dragover}},[_c('div',{staticClass:"status_List"},_vm._l((_vm.unassigned_status),function(list,list_index){return _c('el-tooltip',{key:list_index,attrs:{"disabled":true,"effect":"light","content":list.name,"placement":"top"}},[_c('div',{key:list_index,class:{
                  TODO_class: list.statusCategory === 'TODO',
                  IN_PROGRESS_class: list.statusCategory === 'IN_PROGRESS',
                  DONE_class: list.statusCategory === 'DONE',
                },attrs:{"draggable":""},on:{"dragstart":function($event){$event.stopPropagation();return _vm.unassigned_dragging_status(list_index)},"dragend":function($event){$event.stopPropagation();return _vm.unassigned_dragend_status.apply(null, arguments)}}},[_vm._v(" "+_vm._s(list.name)+" ")])])}),1),_c('p',[_c('i',{staticClass:"iconfont icon-add"}),_c('br'),_vm._v(" "+_vm._s(_vm.$t("board.settingPage.addBarBtn"))+" ")])])],2)])]),_c('el-dialog',{attrs:{"title":_vm.$t('board.settingPage.addBarForm.title'),"visible":_vm.add_block_dialog,"width":"25%","close-on-click-modal":false,"show-close":true},on:{"update:visible":function($event){_vm.add_block_dialog=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.add_block_item,"label-width":"60px","label-position":"left"}},[_c('el-form-item',{attrs:{"label":_vm.$t('board.settingPage.addBarForm.name'),"prop":"name"}},[_c('el-input',{model:{value:(_vm.add_block_item.name),callback:function ($$v) {_vm.$set(_vm.add_block_item, "name", $$v)},expression:"add_block_item.name"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.add_block_dialog = false}}},[_vm._v(_vm._s(_vm.$t("btn.cancelBtn")))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.add_block_confirm}},[_vm._v(_vm._s(_vm.$t("btn.newBtn")))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }