import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'

export function get_file_List_datachart(
  desc,
  pageNumber,
  pageSize,
  projectId,
  sortby,
  condition,
  fuzzyKey
) {
  //获得项目文件列表
  // tag 已修改
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/filter`,
    method: "post",
    params: {
      pageNumber: pageNumber,
      pageSize: pageSize,
    },
    data: {
      fuzzyKey: fuzzyKey,
      queryConditions: condition,
      desc: desc,
      sortby: sortby,
    },

  });
}


export function get_file_List(
  desc,
  pageNumber,
  pageSize,
  projectId,
  sortby,
  condition,
  fuzzyKey
) {
  //获得项目文件列表
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/filter`,
    method: "post",
    params: {
      pageNumber: pageNumber,
      pageSize: pageSize,
    },
    data: {
      fuzzyKey: fuzzyKey,
      queryConditions: condition,
      desc: desc,
      sortby: sortby,
    },

  });
}
export function save_condition(
  name,
  projectId,
  condition,
  nodeQueryType,
  nodeFilterNotification,
  mqlCondition
) {
  //保存筛选条件
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/condition`,
    method: "post",
    data: {
      queryConditions: condition,
      name: name,
      nodeFilterNotification: nodeFilterNotification,
      nodeQueryType: nodeQueryType,
      mqlCondition: mqlCondition,
    },
  });
}
export function save_senior_condition(params
) {
  //新建高级筛选条件
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/condition`,
    method: "post",
    data: params.data,
  });
}

export function condition_get(projectId) {
  //获取筛选条件
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/condition`,
    method: "get",

  });
}

export function condition_delete(projectId, queryConditionId) {
  //删除筛选条件
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/deleteCondition/${queryConditionId}`,
    method: "delete",

  });
}
export function usage(projectId, queryConditionId) {
  //删除筛选条件之前校验
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/condition/${queryConditionId}/usage`,
    method: "get",

  });
}
export function condition_change(projectId, queryConditionId, params) {
  //更改筛选条件
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/modifyCondition/${queryConditionId}`,
    method: "put",
    data: params,
  });
}

export function condition_edit(projectId, queryConditionId, data) {
  //更改筛选条件名字
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/modifyCondition/${queryConditionId}`,
    method: "put",
    data: data
  });
}
export function get_condition_by_id(projectId, queryConditionId) {
  // 根据queryConditionId获取节点筛选条件
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/condition/${queryConditionId}`,
    method: "get",

  });
}

export function get_file_condition_by_id(projectId) {
  // 根据queryConditionId获取节点筛选条件
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/condition`,
    method: "get",

  });
}