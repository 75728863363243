<template>
  <div class="quick_wrap">
    <p class="title">
      {{ $t("board.settingPage.title") }}
    </p>
    <div class="button_wrap">
      <el-button icon="iconfont icon-exit" @click="cancel">{{
        $t("btn.exitEditBtn")
      }}</el-button>
    </div>
    <div class="main_wrap">
      <div>
        <span class="label-weight">{{ $t("board.settingPage.name") }}:</span>
        <div>
          <el-input
            v-model="form_item.name"
            :placeholder="$t('placeholder.input')"
            size="small"
            @blur="settings"
          ></el-input>
        </div>
      </div>
      <div>
        <span class="label-weight">{{ $t("board.settingPage.bar") }}:</span>
        <div class="block_warp">
          <div
            v-for="(item, index) in blockArr.boardColumnList"
            :key="index"
            :style="{
              color: item.color,
              backgroundColor: item.backgroundColor,
            }"
            draggable
            @dragstart="dragging(index)"
            @dragover="dragover(index)"
            @dragend="dragend"
          >
            <div>
              <i
                class="iconfont icon-six_pot"
                style="color: rgb(127, 127, 128)"
              ></i>
              <i
                style="float: right; color: rgb(127, 127, 128)"
                class="iconfont icon-delete"
                @click="delete_node(item)"
              ></i>
            </div>
            <div class="status_List">
              <p
                v-if="project_type !== 'SCRUM'"
                style="
                  color: rgba(112, 112, 112, 1);
                  font-size: 12px;
                  height: auto;
                  padding: 0;
                  margin: 6px 0;
                "
              >
                {{ $t("board.settingPage.maxCardNum") }}:
              </p>
              <p v-if="project_type !== 'SCRUM'" style="padding: 0; margin: 0">
                <el-input-number
                  v-model="item.number"
                  :min="1"
                  :max="5000"
                  size="small"
                  @change="number_change($event, item)"
                ></el-input-number>
              </p>
              <el-tooltip
                v-for="(list, list_index) in item.statusDtoList"
                :key="list_index"
                :disabled="true"
                effect="light"
                :content="list.name"
                placement="top"
              >
                <div
                  draggable
                  :class="{
                    TODO_class: list.statusCategory === 'TODO',
                    IN_PROGRESS_class: list.statusCategory === 'IN_PROGRESS',
                    DONE_class: list.statusCategory === 'DONE',
                  }"
                  @dragstart.stop="dragging_status(index, list_index)"
                  @dragend.stop="dragend_status"
                >
                  {{ list.name }}
                </div>
              </el-tooltip>
            </div>
            <div>
              {{ item.name }}
            </div>
          </div>
          <div
            class="add_block"
            @click="add_block"
            @dragover="unassigned_dragover"
          >
            <div class="status_List">
              <el-tooltip
                v-for="(list, list_index) in unassigned_status"
                :key="list_index"
                :disabled="true"
                effect="light"
                :content="list.name"
                placement="top"
              >
                <div
                  :key="list_index"
                  draggable
                  :class="{
                    TODO_class: list.statusCategory === 'TODO',
                    IN_PROGRESS_class: list.statusCategory === 'IN_PROGRESS',
                    DONE_class: list.statusCategory === 'DONE',
                  }"
                  @dragstart.stop="unassigned_dragging_status(list_index)"
                  @dragend.stop="unassigned_dragend_status"
                >
                  {{ list.name }}
                </div>
              </el-tooltip>
            </div>
            <p>
              <i class="iconfont icon-add"></i>
              <br/>
              {{ $t("board.settingPage.addBarBtn") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="$t('board.settingPage.addBarForm.title')"
      :visible.sync="add_block_dialog"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <el-form
        ref="form"
        :model="add_block_item"
        label-width="60px"
        label-position="left"
      >
        <el-form-item
          :label="$t('board.settingPage.addBarForm.name')"
          prop="name"
        >
          <el-input v-model="add_block_item.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="add_block_dialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="add_block_confirm">{{
          $t("btn.newBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  unassigned_statusList,
  scrumBoard_detail,
  add_unassigned_statusList,
  delete_unassigned_statusList,
  column_order,
  settings,
  column_statusList, //scrum相关接口↑
  kanban_detail, //看板相关接口↓
  kanban_unassigned_statusList,
  kanban_setting,
  kanban_delete_columns,
  kanban_add_columns,
  kanban_column_statuslist,
  kanbanBoard_column_order,
  kanbanBoard_column_number,
} from "@/network/quick/index.js";
import { get_condition } from "@/network/nodePage/index.js";
import { mapGetters } from "vuex";
export default {
  name: "Quicksetting",
  components: {},
  data() {
    return {
      resend: {},
      color_list: [
        "#E4EBD2",
        "#D9E8D3",
        "#DCE6D9",
        "#DAE6E7",
        "#D0E8E8",
        "#D2E2F0",
        "#D5DCEF",
        "#DADFEA",
        "#E3DEE9",
        "#DED6EB",
        "#EBDBE0",
        "#EDD6E4",
        "#E8D6D7",
        "#EED5D6",
        "#EEECD3",
        "#EEE7D3",
        "#EBE1DA",
        "#EEE2D4",
        "#EEDBD4",
        "#E0DED2",
      ],
      form_item: {
        name: "",
        queryConditionId: "",
      },
      filteroptions: [],
      blockArr: [],
      unassigned_status: [],
      add_block_dialog: false,
      add_block_item: {
        name: "",
      },
      from_node: null,
      to_node: null,
      unassigned_status_index: null,
      unassigned_drag_flag: false,
    };
  },
  computed: {
    ...mapGetters(["project_type"]),
  },
  mounted() {
    //获取面板详情
    this.scrumBoard_detail();
    // 获取未分配的状态列表
    this.unassigned_statusList();
    //获取节点筛选下拉
    // this.get_condition();
    if (this.$route.params.data) {
      this.resend = this.$route.params.data;
    }
  },
  methods: {
    number_change(val, item) {
      const params = {
        projectId: item.projectId,
        columnId: item.columnId,
        number: val,
      };
      kanbanBoard_column_number(params)
        .then((res) => {})
        .catch((err) => {
          this.scrumBoard_detail();
        });
    },
    // 分配面板列的状态
    column_statusList() {
      const params = {
        projectId: this.get_pid(),
        data: {
          columnStatusList: [],
        },
      };
      this.blockArr.boardColumnList.forEach((item) => {
        const obj = {
          columnId: "",
          statusIdList: [],
        };
        obj.columnId = item.columnId;
        item.statusDtoList.forEach((status) => {
          obj.statusIdList.push(status.statusId);
        });
        params.data.columnStatusList.push(obj);
      });
      const res =
        this.project_type === "SCRUM"
          ? column_statusList(params)
          : kanban_column_statuslist(params);
      res.then((res) => {});
    },
    // 设置基本信息
    settings() {
      const params = {
        projectId: this.get_pid(),
        data: this.form_item,
      };
      const res =
        this.project_type === "SCRUM"
          ? settings(params)
          : kanban_setting(params);
      res.then((res) => {});
    },
    // 获取节点筛选下拉
    get_condition() {
      this.filteroptions = [];
      const params = {
        projectId: this.get_pid(),
      };
      get_condition(params).then((res) => {
        res.forEach((item) => {
          this.filteroptions.push(item);
        });
      });
    },
    //获取面板详情
    scrumBoard_detail() {
      const params = {
        projectId: this.get_pid(),
      };
      const res =
        this.project_type === "SCRUM"
          ? scrumBoard_detail(params)
          : kanban_detail(params);
      res.then((res) => {
        if (res) {
          this.form_item.name = res.name;
          this.form_item.queryConditionId = res.queryConditionId;
          this.blockArr = res;
          this.blockArr.boardColumnList.forEach((item, index) => {
            item.color = "#000";
            item.backgroundColor = this.color_list[index];
          });
        }
      });
    },
    //获取未分配的状态列表
    unassigned_statusList() {
      const params = {
        projectId: this.get_pid(),
      };
      const res =
        this.project_type === "SCRUM"
          ? unassigned_statusList(params)
          : kanban_unassigned_statusList(params);
      res.then((res) => {
        if (res) {
          this.unassigned_status = res;
        }
      });
    },
    cancel() {
      this.$router.push({
        name: "board",
        params: {
          data: this.resend,
        },
      });
    },
    add_block() {
      this.add_block_item.name = "";
      this.add_block_dialog = true;
    },
    delete_node(item) {
      const params = {
        projectId: this.get_pid(),
        columnId: item.columnId,
      };
      const res =
        this.project_type === "SCRUM"
          ? delete_unassigned_statusList(params)
          : kanban_delete_columns(params);
      res.then((res) => {
        this.scrumBoard_detail();
        this.unassigned_statusList();
      });
    },
    add_block_confirm() {
      const params = {
        projectId: this.get_pid(),
        data: {
          name: this.add_block_item.name,
        },
      };
      const res =
        this.project_type === "SCRUM"
          ? add_unassigned_statusList(params)
          : kanban_add_columns(params);
      res.then((res) => {
        this.scrumBoard_detail();
        this.add_block_dialog = false;
      });
    },
    //列拖拽
    dragging(index) {
      this.from_node = index;
    },
    dragover(index) {
      this.to_node = index;
      this.unassigned_drag_flag = true;
    },
    dragend() {
      this.blockArr.boardColumnList.splice(
        this.from_node,
        1,
        ...this.blockArr.boardColumnList.splice(
          this.to_node,
          1,
          this.blockArr.boardColumnList[this.from_node]
        )
      );
      const params = {
        projectId: this.get_pid(),
        data: {
          orderColumnList: [],
        },
      };
      this.blockArr.boardColumnList.forEach((item) => {
        params.data.orderColumnList.push(item.columnId);
      });
      const res =
        this.project_type === "SCRUM"
          ? column_order(params)
          : kanbanBoard_column_order(params);
      res.then((res) => {});
    },
    // 已分配状态拖拽
    dragging_status(index, status_index) {
      this.status_from = index;
      this.status_index = status_index;
    },
    dragend_status() {
      if (this.unassigned_drag_flag) {
        this.blockArr.boardColumnList[this.to_node].statusDtoList.push(
          this.blockArr.boardColumnList[this.status_from].statusDtoList.splice(
            this.status_index,
            1
          )[0]
        );
      } else {
        this.unassigned_status.push(
          this.blockArr.boardColumnList[this.status_from].statusDtoList.splice(
            this.status_index,
            1
          )[0]
        );
      }
      this.column_statusList();
    },
    // 未分配状态拖拽
    unassigned_dragging_status(index) {
      this.unassigned_status_index = index;
    },
    unassigned_dragend_status() {
      if (this.unassigned_drag_flag) {
        this.blockArr.boardColumnList[this.to_node].statusDtoList.push(
          this.unassigned_status.splice(this.unassigned_status_index, 1)[0]
        );
        this.column_statusList();
      }
    },
    unassigned_dragover() {
      this.unassigned_drag_flag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.label-weight {
  font-weight: bold;
}
.quick_wrap {
  text-align: left;
  height: 100%;
  overflow: hidden;
  position: relative;
  .title {
    color: rgba(56, 56, 56, 1);
    font-size: 20px;
    font-weight: 700;
    margin-top: 0;
  }
  .button_wrap {
    text-align: right;
    position: absolute;
    top: 0;
    right: 0;
  }
  .main_wrap {
    width: 100%;
    height: 720px;
    overflow-y: scroll;
    background: rgba(255, 255, 255, 1);
    margin-top: 32px;
    border-radius: 4px;
    padding: 40px 35px;
    & > div {
      margin-bottom: 38px;
      display: flex;
      align-items: baseline;
      &:last-of-type {
        align-items: flex-start;
      }
      & > span {
        display: inline-block;
        width: 100px;
        color: rgba(112, 112, 112, 1);
        font-size: 16px;
      }
      & > div {
        width: 370px;
      }
      .block_warp {
        min-height: 450px;
        display: flex;
        & > div {
          width: 100%;
          margin-right: 37px;
          border-radius: 4px;
          padding: 4px 8px 20px 8px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          cursor: pointer;
          &:last-of-type {
            margin-right: 0;
          }
          & > .status_List {
            height: 100%;
            padding: 10px;
            & > div {
              display: inline-block;
              padding: 0 10px;
              margin: 4px 4px;
              height: 60px;
              line-height: 60px;
              border-radius: 8px;
              max-width: 130px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .TODO_class {
              background-color: rgb(193, 193, 193);
              color: #fff;
            }
            .IN_PROGRESS_class {
              color: rgb(42, 130, 228);
              border: 1px solid rgb(42, 130, 228);
              background-color: rgb(255, 255, 255);
            }
            .DONE_class {
              color: rgb(165, 214, 63);
              border: 1px solid rgb(165, 214, 63);
              background-color: rgb(255, 255, 255);
            }
          }
        }
        .add_block {
          background-color: rgba(230, 230, 230, 1);
          color: rgba(0, 0, 0, 1);
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding-bottom: 20px;
          min-width: 85px;
          & > p {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
